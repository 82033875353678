import React from "react"
import ComingSoon from "./screens/ComingSoon"

import "./App.scss"

function App() {
    return (
        <React.Fragment>
            <ComingSoon />
        </React.Fragment>
    )
}

export default App
