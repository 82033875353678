import React from "react"

import "./ComingSoon.scss"

import logo from "../assets/logo.png"

const ComingSoon = () => {
    return (
        <section id="coming-soon">
            <div className="content">
                <img src={logo} alt="" />
            </div>
        </section>
    )
}

export default ComingSoon
